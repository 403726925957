import React, { useCallback, useMemo, useRef, useState } from 'react';
import { CountryEnum } from '@/constants/country';
import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@/src/components/UI/FilterContainer';
import {
  AreaOptions,
  BuildingShowcaseEntity,
  GetOptionsForBuildingFilter,
  LocationData,
  LocationDataEntity,
} from '@/swagger';
import classNames from 'classnames';
import CustomRadio from '@/src/components/UI/CustomRadio';
import Image from 'next/image';
import { isNotEmptyArray, isString } from '@/utils/formatChecker';
import ConditionalFragment from '@/components/common/ConditionalFragment';
import useIsTopOrEndReached from '@/src/hooks/useIsTopOrEndReached';
import { useRouter } from 'next/router';
import { useAsync } from 'react-use';
import { locationData } from '@/lib/constant';
import ScrollableMaskContainer from '@/components/common/ScrollableMaskContainer';

const getTop = (el: HTMLElement): number => el.getBoundingClientRect().top + el.clientHeight + 8;
const getLeft = (el: HTMLElement): number => el.getBoundingClientRect().left;
export const getCityLocationData = (state: string, city: string, location: Array<LocationData>) => {
  const theState = location.find((stateLocationData) => stateLocationData.acceptanceSearchKeywords.includes(state));
  return theState?.cities.find((cityLocationData) => cityLocationData.acceptanceSearchKeywords.includes(city));
};
export const filterShowcasesByCity = (
  showcases: Array<BuildingShowcaseEntity>,
  _cities: string[] | string,
  location: LocationDataEntity | undefined
) => {
  const cities = isString(_cities) ? [_cities] : _cities ?? [];
  return showcases.filter((showcase) => {
    if (cities.length === 0) return false;
    if (showcase.country === 'TW') {
      const replacedCities = cities.map((city) => city.replace('臺', '台'));
      return replacedCities.includes(showcase.city.replace('臺', '台'));
    } else {
      const locationData = (showcase.country === 'US' ? location?.US : location?.CA) ?? [];
      const showcaseCityLocationData = getCityLocationData(showcase.state ?? '', showcase.city ?? '', locationData);
      if (!showcaseCityLocationData) return false;
      return cities.some((city) => showcaseCityLocationData.acceptanceSearchKeywords.includes(city));
    }
  });
};

const BuildingAreaFilter: React.FC<{
  showcases: Array<BuildingShowcaseEntity>;
  areaData: GetOptionsForBuildingFilter;
}> = ({ showcases, areaData }) => {
  const [filteredCountry, setFilteredCountry] = useState<keyof typeof CountryEnum>('ALL');
  const [filteredCities, setFilteredCities] = useState<string[]>([]);
  const { value: location } = useAsync(() => locationData, []);

  const router = useRouter();
  const { cities, country } = router.query as { cities: string[]; country: keyof typeof CountryEnum };

  const modalTriggerRef = useRef<HTMLButtonElement>(null);

  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen() {
      if (!country || country === 'ALL') return;
      setFilteredCountry(country);
      setFilteredCities(isString(cities) ? [cities] : cities ?? []);
    },
  });

  const areaCountryTitleString = useMemo(() => {
    if (!country) return CountryEnum.ALL;
    return CountryEnum[country];
  }, [country]);
  const areaCityTitleString = useMemo(() => {
    const _cities = isString(cities) ? [cities] : cities ?? [];
    if (_cities.length > 0) {
      return _cities.join(', ');
    } else {
      return '';
    }
  }, [cities]);

  const filterTop = useMemo(() => {
    return isOpen && modalTriggerRef.current ? getTop(modalTriggerRef.current) : 0;
  }, [isOpen]);

  const filterLeft = useMemo(() => {
    return isOpen && modalTriggerRef.current ? getLeft(modalTriggerRef.current) : 0;
  }, [isOpen]);

  const hasTWItem = useMemo(() => {
    return isNotEmptyArray(areaData.twCitiesData);
  }, [showcases]);
  const hasUSItem = useMemo(() => {
    return isNotEmptyArray(areaData.usCitiesData);
  }, [showcases]);
  const hasCAItem = useMemo(() => {
    return isNotEmptyArray(areaData.caCitiesData);
  }, [showcases]);

  const countryOptions = useMemo<Array<Option<keyof typeof CountryEnum>>>(() => {
    return [
      { value: 'ALL' as keyof typeof CountryEnum, label: '全部區域', active: true },
      { value: 'TW' as keyof typeof CountryEnum, label: '台灣', active: hasTWItem },
      { value: 'US' as keyof typeof CountryEnum, label: '美國', active: hasUSItem },
      { value: 'CA' as keyof typeof CountryEnum, label: '加拿大', active: hasCAItem },
    ].filter((option) => option.active);
  }, [hasTWItem, hasUSItem, hasCAItem]);

  const resultNum = useMemo(() => {
    return filteredCountry === 'ALL'
      ? showcases.length
      : filterShowcasesByCity(showcases, filteredCities, location).length;
  }, [showcases, filteredCountry, filteredCities, location]);

  const onCitySelect = useCallback((city: string) => {
    setFilteredCities((prev) => (prev.includes(city) ? prev.filter((c) => c !== city) : [...prev, city]));
  }, []);
  const onCountrySelect = useCallback((country: keyof typeof CountryEnum) => {
    setFilteredCountry(country);
    setFilteredCities([]);
  }, []);
  const onReset = useCallback(() => {
    setFilteredCountry('ALL');
    setFilteredCities([]);
  }, []);

  const closeFilter = useCallback(() => {
    setFilteredCountry(country);
    setFilteredCities(cities);
    onClose();
  }, [country, cities]);

  const onApply = useCallback(() => {
    router.replace(
      {
        pathname: router.pathname,
        query:
          filteredCountry === 'ALL'
            ? {}
            : {
                country: filteredCountry,
                cities: filteredCities,
              },
      },
      undefined,
      { shallow: true }
    );
    onClose();
  }, [filteredCountry, filteredCities, router, onClose]);

  return (
    <React.Fragment>
      <Flex px={4}>
        <Flex id={'city-selector'} className={'md:flex-[none] flex-1 flex-shrink-0 overflow-hidden'}>
          <Button
            ref={modalTriggerRef}
            textColor={'black'}
            p={{ base: 4 }}
            py={{ sm: 2 }}
            w={{ sm: '20vw', base: '100%' }}
            h={'auto'}
            overflowX={'clip'}
            backgroundColor={'white'}
            borderColor={'black'}
            borderWidth={{ sm: isOpen ? 2 : 1, base: 1 }}
            onClick={onOpen}
          >
            <HStack w={'full'} justify={'space-between'} align={'center'}>
              <HStack className={'flex-1'} align={'center'} overflow={'hidden'}>
                <Text>{areaCountryTitleString}</Text>
                {areaCityTitleString != '' && (
                  <>
                    <Stack w={'1px'} h={'20px'} bgColor={'black'} />
                    <Text
                      overflow={'hidden'}
                      isTruncated
                      textOverflow={'ellipsis'}
                      textAlign={'left'}
                      className={'flex-1'}
                    >
                      {areaCityTitleString}
                    </Text>
                  </>
                )}
              </HStack>
              <Stack marginLeft={'auto'}>
                <ChevronDownIcon isActive={isOpen} />
              </Stack>
            </HStack>
          </Button>
        </Flex>
      </Flex>
      <Modal onClose={closeFilter} isOpen={isOpen}>
        <ModalContent
          position={{ md: 'absolute', base: 'fixed' }}
          top={{ md: `${filterTop}px` }}
          left={{ md: `${filterLeft}px`, base: 0 }}
          bottom={{ md: 'auto', base: 0 }}
          m={{ base: 0 }}
          width={{ base: '100vw', md: 'auto' }}
          maxWidth={{ md: 'auto' }}
          height={{ base: '100vh', md: 'auto' }}
          alignSelf={'self-start'}
          borderRadius={0}
          background={{ base: 'rgba(28, 30, 33, 0.4)' }}
          zIndex={{ base: 99999 }}
        >
          <ModalBody
            display={'flex'}
            position={{ base: 'absolute', md: 'relative' }}
            bottom={{ base: 0 }}
            left={{ base: 0 }}
            px={{ base: 0 }}
            py={{ base: 4, md: 0 }}
            height={{ base: 'fit-content', md: 'auto' }}
            maxHeight={{ base: '80vh' }}
            width={{ md: '624px' }}
            minWidth={{ md: 'auto', base: '100vw' }}
            backgroundColor={'white'}
            className={'shadow-dropdown overflow-hidden'}
          >
            <Flex w={'full'} flexDirection={'column'} className={'flex-1 overflow-hidden'}>
              <Flex
                flexDirection={'column'}
                w={'full'}
                px={{ md: 8, base: 6 }}
                pt={{ md: 4 }}
                className={'flex-1 overflow-hidden'}
              >
                <Flex
                  as={'button'}
                  onClick={closeFilter}
                  className={'w-6 h-10 py-2'}
                  display={{ md: 'none' }}
                  mb={4}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                >
                  <Image src={'/close-outline.svg'} layout={'fixed'} width={24} height={24} />
                </Flex>
                <div className={classNames('-mx-6 md:mb-4 w-screen md:w-full', 'flex flex-col flex-1 overflow-hidden')}>
                  <VStack w={'full'}>
                    <CustomRadio
                      disableDesktopExtraStyle
                      activeIdx={filteredCountry}
                      onChange={onCountrySelect}
                      defaultIdx={0}
                      options={countryOptions}
                    />
                  </VStack>
                  <ConditionalFragment condition={filteredCountry !== 'ALL'}>
                    <Stack
                      bgColor={'gray.200'}
                      w={'full'}
                      h={'1px'}
                      my={{ md: '4' }}
                      className={'px-6 flex-shrink-0'}
                    />
                  </ConditionalFragment>
                  <div className={classNames('px-4 w-screen md:w-full', 'relative flex flex-col overflow-scroll')}>
                    {filteredCountry === 'TW' && (
                      <CityOptions data={areaData.twCitiesData} cities={filteredCities} onCitySelect={onCitySelect} />
                    )}
                    {filteredCountry === 'US' && (
                      <CitySectionOptions
                        data={areaData.usCitiesData}
                        cities={filteredCities}
                        onCitySelect={onCitySelect}
                        location={location?.US}
                      />
                    )}
                    {filteredCountry === 'CA' && (
                      <CitySectionOptions
                        data={areaData.caCitiesData}
                        cities={filteredCities}
                        onCitySelect={onCitySelect}
                        location={location?.CA}
                      />
                    )}
                  </div>
                </div>
                <Text
                  fontSize={'xs'}
                  color={'gray.500'}
                  bg={{ base: 'gray.100', md: 'transparent' }}
                  mx={-6}
                  px={6}
                  py={2}
                >
                  篩選不到合適物件？撥打 02-7752-3755，我們將提供高效專業的買代尋服務。
                </Text>
              </Flex>
              <HStack
                w={'full'}
                justify={'space-between'}
                borderTop={1}
                borderStyle={'solid'}
                borderColor={'gray.300'}
                py={{ md: 4, base: 2 }}
                px={{ md: 8, base: 6 }}
                className={'flex-shrink-0'}
              >
                <Button
                  bgColor={'white'}
                  fontSize={{ base: '16px' }}
                  textColor={'darkgray'}
                  textDecorationLine={'underline'}
                  onClick={onReset}
                  px={0}
                  m={0}
                >
                  <Text fontWeight={'500'}>重新設定</Text>
                </Button>
                <Button
                  px={{ base: 8 }}
                  py={{ base: 3 }}
                  fontSize={{ base: '16px' }}
                  textColor={'white'}
                  onClick={onApply}
                >
                  查看 {resultNum} 筆結果
                </Button>
              </HStack>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
};

export default BuildingAreaFilter;

const CityOptions: React.FC<{ data: Array<string>; cities: Array<string>; onCitySelect: (city: string) => void }> = ({
  data,
  cities,
  onCitySelect,
}) => {
  const boxRef = useRef<HTMLDivElement | null>(null);

  const { onContainerScroll, bottomEndReached, topEndReached } = useIsTopOrEndReached({ container: boxRef.current });

  return (
    <React.Fragment>
      <Box
        ref={boxRef}
        onScroll={onContainerScroll}
        id={'city-option-container'}
        w={'full'}
        display={'flex'}
        flexWrap={{ md: 'wrap' }}
        flexDirection={{ base: 'column', md: 'row' }}
        className={'relative overflow-scroll'}
      >
        {data.map((city) => {
          const isSelected = cities.includes(city);

          return (
            <Button
              p={{ base: 0 }}
              py={{ md: 1, base: 4 }}
              pr={{ md: 4 }}
              height={'auto'}
              bgColor={'transparent'}
              key={city}
              onClick={() => {
                onCitySelect(city);
              }}
            >
              <Flex
                align={'center'}
                justify={{ md: 'center', base: 'space-between' }}
                flexDirection={{ md: 'row', base: 'row-reverse' }}
                w={'full'}
                borderColor={isSelected ? 'black' : 'gray.500'}
              >
                <Stack
                  border={'2px solid black'}
                  position={'relative'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  mr={{ md: 2 }}
                  width={18}
                  height={18}
                  borderRadius={4}
                >
                  <ConditionalFragment condition={isSelected}>
                    <Image src={'/check-icon.svg'} layout={'fill'} />
                  </ConditionalFragment>
                </Stack>
                <Text fontSize={16} lineHeight={'24px'} letterSpacing={0.5} fontWeight={'400'} textColor={'black'}>
                  {city}
                </Text>
              </Flex>
            </Button>
          );
        })}
      </Box>
      <div
        className={classNames('md:invisible md:hidden absolute top-0 left-0 w-full h-16 pointer-events-none', {
          'block visible': !topEndReached,
          'hidden invisible': topEndReached,
        })}
        style={{
          backgroundImage: 'linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.4) 100%)',
        }}
      />
      <div
        className={classNames('md:invisible md:hidden absolute bottom-0 left-0 w-full h-16 pointer-events-none', {
          'block visible': !bottomEndReached,
          'hidden invisible': bottomEndReached,
        })}
        style={{
          backgroundImage: 'linear-gradient(to top, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.4) 100%)',
        }}
      />
    </React.Fragment>
  );
};

const CitySectionOptions: React.FC<{
  data: Array<AreaOptions>;
  cities: Array<string>;
  onCitySelect: (city: string) => void;
  location: Array<LocationData> | undefined;
}> = ({ data, cities, onCitySelect, location = [] }) => {
  const getStateTitle = useCallback(
    (key: string) => {
      const usLocation = location;
      if (!usLocation) return '';
      const target = usLocation.find((state) => state.key === key);
      if (!target) return '';
      return `${target.zh_tw} (${target.en})`;
    },
    [location]
  );

  if (!location) return null;

  return (
    <div className={'-mx-4 h-full'}>
      <ScrollableMaskContainer className={'space-y-2 p-4 md:py-0'} direction={'vertical'}>
        {data.map((state) => {
          return (
            <div key={state.state}>
              <p className={'text-lg md:text-base font-bold md:font-medium text-gray900 underline md:no-underline'}>
                {getStateTitle(state.state)}
              </p>
              <Box
                id={'city-option-container'}
                w={'full'}
                display={'flex'}
                flexWrap={{ md: 'wrap' }}
                flexDirection={{ base: 'column', md: 'row' }}
                className={'relative overflow-scroll'}
              >
                {state.cities.map((city) => {
                  const isSelected = cities.includes(city);

                  return (
                    <Button
                      p={{ base: 0 }}
                      py={{ md: 1, base: 4 }}
                      pr={{ md: 4 }}
                      height={'auto'}
                      bgColor={'transparent'}
                      key={city}
                      onClick={() => {
                        onCitySelect(city);
                      }}
                    >
                      <Flex
                        align={'center'}
                        justify={{ md: 'center', base: 'space-between' }}
                        flexDirection={{ md: 'row', base: 'row-reverse' }}
                        w={'full'}
                        borderColor={isSelected ? 'black' : 'gray.500'}
                      >
                        <Stack
                          border={'2px solid black'}
                          position={'relative'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          mr={{ md: 2 }}
                          width={18}
                          height={18}
                          borderRadius={4}
                        >
                          <ConditionalFragment condition={isSelected}>
                            <Image src={'/check-icon.svg'} layout={'fill'} />
                          </ConditionalFragment>
                        </Stack>
                        <Text
                          fontSize={16}
                          lineHeight={'24px'}
                          letterSpacing={0.5}
                          fontWeight={'400'}
                          textColor={'black'}
                        >
                          {city}
                        </Text>
                      </Flex>
                    </Button>
                  );
                })}
              </Box>
            </div>
          );
        })}
      </ScrollableMaskContainer>
    </div>
  );
};
